import { TYPE_BROADCAST } from './types';
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION]: `*_GROOM & BRIDE WEDDING_* 

Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*
  
Perkenalkan, kami dari *Invitato* mewakili keluarga pengantin ingin menyampaikan undangan pernikahan: 
  
*Groom & Bride*
  
*Mohon klik link undangan di bawah untuk informasi lebih lanjut:*
{{LINK}}
_*Jika link tidak berwarna biru/tidak muncul silakan untuk coba membalas chat ini dengan "YA", atau simpan nomor ini untuk dapat membuka link.*_

*Acara pernikahan akan diselenggarakan pada:* 
Hari: Sabtu, 26 Agustus 2023
Waktu: 16.00 - 18.00 WIB
Lokasi: Galea Belangi, Bekasi
  
*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir?
_Jawab YA/TIDAK_
2. Apabila hadir, berapa jumlah tamu yang akan menghadiri acara? 
_Jawab dengan angka_
  
Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal sampai tanggal *21 Agustus 2023*
  
Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga.
  
Mohon sampaikan konfirmasi kehadiran Anda dengan cara membalas pesan ini.
  
Terima kasih.
  
_Kami yang berbahagia,_
*Groom, Bride & keluarga*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION2]: `_*EVAN & BERNIKA WEDDING*_ 

Dear Mr/Mrs/Ms, 
*{{GUEST_NAME}}*
  
On behalf of the family of  *Evan Nathan Kristiadi & Bernika Putri Hardjo*, we would like to ask again regarding confirmation of your presence at *Evan & Bernika*'s wedding.
  
Will you attend *Evan & Bernika*'s wedding? We are waiting for your confirmation of attendance (RSVP).

Please confirm your attendance by filling in and clicking the *Submit RSVP* button on the wedding invitation link below:

{{LINK}}

We are waiting for your confirmation of attendance until *January 20, 2024*. Your attendance will be a great honor for us and our families.

Please confirm your attendance immediately as it will be very useful for the preparations of the bride and groom's wedding later.

Thank you ☺️🙏🏻`,

  CUSTOM_REMINDER: `Selamat siang, Bapak/Ibu, {{GUEST_NAME}} 😊🙏🏻 

Mewakili keluarga *Groom & Bride*, kami ingin mengingatkan batas maksimal konfirmasi kehadiran yaitu hari ini, 21 Agustus 2023 . 
  
Apakah Bapak/Ibu dapat menghadiri acara pernikahan *Groom & Bride*?  
  
Kami tunggu segera konfirmasinya Bapak/Ibu supaya bisa kami catat dan sampaikan ke keluarga pengantin.  
Terima kasih.`,

  CUSTOM_REMINDER_EVENT: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami dari *Invitato* ingin mengingatkan undangan pernikahan *Groom & Bride*.

*Acara pernikahan akan diselenggarakan pada:* 
Hari: Sabtu, 26 Agustus 2023
Waktu: 16.00 - 18.00 WIB
Lokasi: Galea Belangi, Bekasi

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *{{TOTAL_GUEST}} orang*.

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Groom & Bride.

_Kami yang berbahagia,_
*Groom, Bride & keluarga*`,

  RSVP_SHIFT_1_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

*Mr. Daniel Tirta Kristiadi & Mrs. Susanna Siska*
_together with_
*Mr. Rudi Hardjo & Mrs. Linda*

Joyfully invite you to the wedding celebration of their son and daughter
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*RECEPTION*_
Date: *Saturday, 03rd February 2024*
Time: *18.00 WIB*
Location: *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Your total quota of guests: *{{GUEST_QUOTA}} persons*
_Since its a seated event please do submit confirmation of attendance according to the number of pax allocated_

*Please kindly confirm your attendance before 20th of January, 2024*
{{LINK}}

_If you can't click the link above please reply to this message first._

Thank you🙏`,

  RSVP_SHIFT_2_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

*Mr. Daniel Tirta Kristiadi & Mrs. Susanna Siska*
_together with_
*Mr. Rudi Hardjo & Mrs. Linda*

Joyfully invite you to the wedding celebration of their son and daughter
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*HOLY MATRIMONY*_
Date: *Saturday, 03rd February 2024*
Time: *11.00 WIB*

_*RECEPTION*_
Date: *Saturday, 03rd February 2024*
Time: *18.00 WIB*

📍*St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Your total quota of guests: *{{GUEST_QUOTA}} persons*
_Since its a seated event please do submit confirmation of attendance according to the number of pax allocated_

*Please kindly confirm your attendance before 20th of January, 2024*
{{LINK}}

_If you can't click the link above please reply to this message first._

Thank you🙏`,

  RSVP_SHIFT_3_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

*Mr. Daniel Tirta Kristiadi & Mrs. Susanna Siska*
_together with_
*Mr. Rudi Hardjo & Mrs. Linda*

Joyfully invite you to the wedding celebration of their son and daughter
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*HOLY MATRIMONY*_
Date: *Saturday, 03rd February 2024*
Time: *11.00 WIB*

_*TEA PAI*_
Date: *Saturday, 03rd February 2024*
Time: *13.00 WIB*

_*RECEPTION*_
Date: *Saturday, 03rd February 2024*
Time: *18.00 WIB*

📍 *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Your total quota of guests: *{{GUEST_QUOTA}} persons*

*Please kindly confirm your attendance before 20th of January, 2024*
{{LINK}}

_If you can't click the link above please reply to this message first._

Thank you🙏`,

  RSVP_SHIFT_1_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

*Bapak Daniel Tirta Kristiadi & Ibu Susanna Siska*
_bersama dengan_
*Bapak Rudi Hardjo & Ibu Linda*

Dengan senang hati mengundang Anda pada pernikahan:
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*RESEPSI*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *18.00 WIB*
Lokasi: *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Kuota Tamu Anda: *{{GUEST_QUOTA}} orang*
_Karena acara pernikahan diselenggarakan secara seating, mohon konfirmasikan kehadiran Anda sesuai dengan jumlah tamu yang sudah disediakan_

*Mohon konfirmasi kehadiran Anda sebelum 20 Januari 2024*
{{LINK}}

_Jika link di atas tidak dapat diklik harap balas pesan ini terlebih dahulu._

Terima kasih 🙏`,

  RSVP_SHIFT_2_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

*Bapak Daniel Tirta Kristiadi & Ibu Susanna Siska*
_bersama dengan_
*Bapak Rudi Hardjo & Ibu Linda*

Dengan senang hati mengundang Anda pada pernikahan:
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*HOLY MATRIMONY*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *11.00 WIB*

_*RESEPSI*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *18.00 WIB*

📍 *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Kuota Tamu Anda: *{{GUEST_QUOTA}} orang*
_Karena acara pernikahan diselenggarakan secara seating, mohon konfirmasikan kehadiran Anda sesuai dengan jumlah tamu yang sudah disediakan_

*Mohon konfirmasi kehadiran Anda sebelum 20 Januari 2024*
{{LINK}}

_Jika link di atas tidak dapat diklik harap balas pesan ini terlebih dahulu._

Terima kasih 🙏`,

  RSVP_SHIFT_3_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

*Bapak Daniel Tirta Kristiadi & Ibu Susanna Siska*
_bersama dengan_
*Bapak Rudi Hardjo & Ibu Linda*

Dengan senang hati mengundang Anda pada pernikahan:
_*Evan Nathan Kristiadi & Bernika Putri Hardjo*_

_*HOLY MATRIMONY*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *11.00 WIB*

_*TEA PAI*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *13.00 WIB*

_*RESEPSI*_
Hari: *Sabtu, 03 Februari 2024*
Waktu: *18.00 WIB*

📍 *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Kuota Tamu Anda: *{{GUEST_QUOTA}} orang*

*Mohon konfirmasi kehadiran Anda sebelum 20 Januari 2024*
{{LINK}}

_Jika link di atas tidak dapat diklik harap balas pesan ini terlebih dahulu._

Terima kasih🙏`,

  REMINDER_RSVP_SHIFT_1_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

On behalf of the family of *Evan Nathan Kristiadi & Bernika Putri Hardjo*, we would like to ask again regarding confirmation of your attendance at *Evan & Bernika*'s wedding.

*CONFIRMATION OF ATTENDANCE:*
1. Will you attend the Wedding Reception?
*Answer:*
Yes, attend the Wedding Reception ... persons
Not attend

*Your total quota of guests: {{GUEST_QUOTA}} persons*
_Since its a seated event please do submit confirmation of attendance according to the number of pax allocated_

*We are waiting for your confirmation of attendance (RSVP) maximum today, January 22, 2024*

Your confirmation of attendance will be very useful for the preparations of the bride and groom's wedding later.

Thank you ☺️🙏🏻`,

  REMINDER_RSVP_SHIFT_2_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

On behalf of the family of *Evan Nathan Kristiadi & Bernika Putri Hardjo*, we would like to ask again regarding confirmation of your attendance at *Evan & Bernika*'s wedding.

*CONFIRMATION OF ATTENDANCE:*
1. Will you attend the Holy Matrimony?
*Answer:*
Yes, attend the Holy Matrimony ... persons
Not attend

2. Will you attend the Wedding Reception?
*Answer:*
Yes, attend the Wedding Reception ... persons
Not attend

*Your total quota of guests: {{GUEST_QUOTA}} persons*
_Since its a seated event please do submit confirmation of attendance according to the number of pax allocated_

*We are waiting for your confirmation of attendance (RSVP) maximum today, January 22, 2024*

Your confirmation of attendance will be very useful for the preparations of the bride and groom's wedding later.

Thank you ☺️🙏🏻`,

 REMINDER_RSVP_SHIFT_3_EN: `*RSVP Wedding Celebration of Evan & Bernika*

Dear *{{GUEST_NAME}}*

On behalf of the family of  *Evan Nathan Kristiadi & Bernika Putri Hardjo*, we would like to ask again regarding confirmation of your attendance at *Evan & Bernika*'s wedding.

*CONFIRMATION OF ATTENDANCE:*
1. Will you attend the Holy Matrimony?
*Answer:*
Yes, attend the Holy Matrimony ... persons
Not attend

2. Will you attend the Wedding Reception?
*Answer:*
Yes, attend the Wedding Reception ... persons
Not attend

3. If you want to attend the Tea Pai Ceremony please come at 13.00 WIB

*Your total quota of guests: {{GUEST_QUOTA}} persons*
_Since its a seated event please do submit confirmation of attendance according to the number of pax allocated_

*We are waiting for your confirmation of attendance (RSVP) maximum today, January 22, 2024*

Your confirmation of attendance will be very useful for the preparations of the bride and groom's wedding later.

Thank you ☺️🙏🏻`,

 REMINDER_RSVP_SHIFT_1_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

Mewakili pihak keluarga *Evan Nathan Kristiadi & Bernika Putri Hardjo*, kami ingin menanyakan kembali mengenai konfirmasi kehadiran Anda pada pernikahan *Evan & Bernika*.

*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Resepsi?
*Jawab:*
Ya, hadir Resepsi ... orang
Tidak hadir

*Kuota Tamu Anda: {{GUEST_QUOTA}} orang*
_Karena acara pernikahan diselenggarakan secara seating, mohon konfirmasikan kehadiran Anda sesuai dengan jumlah tamu yang sudah disediakan_

*Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal hari ini tanggal 22 Januari 2024*

Konfirmasi kehadiran Anda akan sangat berguna untuk persiapan pernikahan kedua mempelai nantinya.

Terima Kasih ☺️🙏🏻`,

  REMINDER_RSVP_SHIFT_2_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

Mewakili pihak keluarga *Evan Nathan Kristiadi & Bernika Putri Hardjo*, kami ingin menanyakan kembali mengenai konfirmasi kehadiran Anda pada pernikahan *Evan & Bernika*.

*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Pemberkatan?
*Jawab:*
Ya, hadir Pemberkatan ... orang
Tidak hadir

2. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Resepsi?
*Jawab:*
Ya, hadir Resepsi ... orang
Tidak hadir

*Kuota Tamu Anda: {{GUEST_QUOTA}} orang*
_Karena acara pernikahan diselenggarakan secara seating, mohon konfirmasikan kehadiran Anda sesuai dengan jumlah tamu yang sudah disediakan_

*Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal hari ini tanggal 22 Januari 2024*

Konfirmasi kehadiran Anda akan sangat berguna untuk persiapan pernikahan kedua mempelai nantinya.

Terima Kasih ☺️🙏🏻`,

  REMINDER_RSVP_SHIFT_3_ID: `*RSVP Pernikahan Evan & Bernika*

Yth. *{{GUEST_NAME}}*

Mewakili pihak keluarga *Evan Nathan Kristiadi & Bernika Putri Hardjo*, kami ingin menanyakan kembali mengenai konfirmasi kehadiran Anda pada pernikahan *Evan & Bernika*.

*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Pemberkatan?
*Jawab:*
Ya, hadir Pemberkatan ... orang
Tidak hadir

2. Apakah Bapak/Ibu/Saudara/i berkenan hadir di Resepsi?
*Jawab:*
Ya, hadir Resepsi ... orang
Tidak hadir

3. Jika Bapak/Ibu/Saudara/i ingin menghadiri acara Tea Pai mohon untuk datang pada pukul 13.00 WIB

*Kuota Tamu Anda: {{GUEST_QUOTA}} orang*
_Karena acara pernikahan diselenggarakan secara seating, mohon konfirmasikan kehadiran Anda sesuai dengan jumlah tamu yang sudah disediakan_

*Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal hari ini tanggal 22 Januari 2024*

Konfirmasi kehadiran Anda akan sangat berguna untuk persiapan pernikahan kedua mempelai nantinya.

Terima Kasih ☺️🙏🏻`,
  
  REMINDER_EVENT_SHIFT_1: `Yth. *{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami ingin mengingatkan undangan pernikahan *Evan Nathan Kristiadi & Bernika Putri Hardjo*.

_*RESEPSI*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *18.00 WIB*
Lokasi: *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara pernikahan dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

_*Acara pernikahan diselenggarakan secara seating table*_

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Evan & Bernika.

_Kami yang berbahagia,_
*Evan, Bernika & keluarga*`,

  REMINDER_EVENT_SHIFT_2: `Yth. *{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami ingin mengingatkan undangan pernikahan *Evan Nathan Kristiadi & Bernika Putri Hardjo*.

_*HOLY MATRIMONY*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *11.00 WIB*

_*RESEPSI*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *18.00 WIB*

📍 *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara pernikahan dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

_*Acara pernikahan diselenggarakan secara seating table*_

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Evan & Bernika.

_Kami yang berbahagia,_
*Evan, Bernika & keluarga*`,
  
  REMINDER_EVENT_SHIFT_3: `Yth. *{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami ingin mengingatkan undangan pernikahan *Evan Nathan Kristiadi & Bernika Putri Hardjo*.

_*HOLY MATRIMONY*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *11.00 WIB*

_*TEA PAI*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *13.00 WIB*

_*RESEPSI*_
Hari: *Sabtu, 3 Februari 2024*
Waktu: *18.00 WIB*

📍 *St. Regis Jakarta, Astor Ballroom, Level 2*
*_Jl. HR. Rasuna Said Kav. 4, Kuningan, South Jakarta_*

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara pernikahan dan berlaku untuk *{{GUEST_CONFIRM}} orang*.

_*Acara pernikahan diselenggarakan secara seating table*_

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan Evan & Bernika.

_Kami yang berbahagia,_
*Evan, Bernika & keluarga*`,
};
