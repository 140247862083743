import React, { useState, useMemo } from 'react';
import { oneOf } from 'prop-types';
import {
  Box,
  Text,
  Button,
  Link,
  FormControl,
  Input,
  FormLabel,
  Select,
  InputGroup,
  // InputLeftAddon,
  Center,
  useToast,
  Icon,
  Flex,
  Spacer,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { RiFileCopy2Line, RiWhatsappLine } from 'react-icons/ri';
import { AiOutlineQuestionCircle as QuestionOutlineIcon } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';

import useAddNewGuest from '@/usecase/use-add-new-guest';
import useFormFields from '@hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import { RSVP_STATUS } from '@/constants/api';
import { COUNTRY_CODES } from '@/constants/country-codes';
import generateShift, { SHIFT_LIST } from '@hooks/useShift';

import copyTextToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import getBroadcastText from '../getBroadcastText';
import ModalJenisUndangan from './ModalJenisUndangan';

import { THE_BRIDE, HOSTNAME, WEDDING_RESEPSI_TIME } from '@/constants';
import {
  ENABLE_GUEST_PERSONALIZATION,
  ENABLE_MULTI_LANGUAGE,
  ENABLE_QR_INVITATION,
  ENABLE_SHIFT_TIME_RECEPTION,
} from '@/constants/feature-flags';
import {
  CUSTOM_MODE,
  LIST_BROADCAST,
  INPUT_PROPS,
  SELECT_PROPS,
  FORM_LABEL_OPTIONS,
  BUTTON_PROPS_OPEN,
  PHONE_SELECT_PROPS,
  PHONE_INPUT_PROPS,
} from '../types';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import ModalBroadcast from './ModalBroadcast';

const PARTNER_LIST = [
  {
    name: '1 Tamu',
    value: '1',
  },
  {
    name: '2 Tamu',
    value: '2',
  },
  {
    name: '3 Tamu',
    value: '3',
  },
  // {
  //   name: '4 Tamu',
  //   value: '4',
  // },
  // {
  //   name: '5 Tamu',
  //   value: '5',
  // },
];

const ERROR_TYPE = {
  name: undefined,
  shift: undefined,
  total_guest: undefined,
};

/**
 * function to render Custom Invitation Generator
 * @param {props} props
 * @returns {JSX.Element}
 */
function CustomScreen({ type }) {
  const { onUpdateRsvp } = useRsvp();
  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: '',
    address: '-',
    email: '-',
    phone_number: '-',
    total_guest: ENABLE_GUEST_PERSONALIZATION ? 0 : 2,
    shift: ENABLE_SHIFT_TIME_RECEPTION ? '' : '-',
    desc: '-',
  });

  const [broadcastId, setBroadcastId] = useState('');
  const [lang, setLang] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [modalState, setModalState] = useState('');
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [guestCode, setGuestCode] = useState('');
  const [isGeneralInvitation, setIsGeneralInvitation] = useState(false);

  const toast = useToast();
  const { onAddNewGuest } = useAddNewGuest();
  const [selectedCountryCode, setSelectedCountryCode] = useState('62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  const isInvitation = CUSTOM_MODE.invitation === type;

  const onClose = () => {
    setModalState('');
  };

  const finalUrlLink = useMemo(() => {
    const basicLink = HOSTNAME + '?' + `to=${encodeURIComponent(formFields.name)}` + lang;
    const invitationLink = `${isInvitation ? '&type=invitation' : ''}`;
    const finalUrlLink = basicLink + invitationLink;

    if (!isGeneralInvitation) {
      return finalUrlLink + `${guestCode ? `&code=${guestCode}` : ''}`;
    }

    return (
      finalUrlLink +
      `${ENABLE_SHIFT_TIME_RECEPTION ? `&shift=${formFields.shift}` : ''}` +
      `${ENABLE_GUEST_PERSONALIZATION ? `&partner=${formFields.total_guest}` : ''}` +
      `${isGeneralInvitation ? `&m=g` : ''}`
    );
  }, [formFields, lang, guestCode]);

  const finalTextBroadcast = useMemo(() => {
    if (!broadcastId) return '';

    const result = getBroadcastText({
      id: broadcastId,
      link: finalUrlLink,
      guestName: formFields.name,
      time: `${
        ENABLE_SHIFT_TIME_RECEPTION ? generateShift(formFields.shift) : WEDDING_RESEPSI_TIME
      }`,
    });

    return result;
  }, [broadcastId, finalUrlLink, formFields.name, formFields.shift]);

  /**
   * Function to get list of broadcast text option
   * @returns {Object[]}
   */
  const broadcastOptions = useMemo(() => LIST_BROADCAST.filter((x) => x.type.includes(type)), [
    type,
  ]);

  const errorChecker = () => {
    const { name, total_guest, shift } = formFields;
    setErrorType(ERROR_TYPE);
    if (
      !name ||
      name.length < 3 ||
      (ENABLE_SHIFT_TIME_RECEPTION && isInvitation && !shift) ||
      (ENABLE_GUEST_PERSONALIZATION && isInvitation && !total_guest)
    ) {
      setErrorType({
        name: !name ? 'Wajib diisi' : name.length <= 3 ? 'Nama minimal memiliki 3 karakter' : '',
        shift: !shift ? 'Wajib dipilih' : '',
        total_guest: !total_guest ? 'Wajib dipilih' : '',
      });
      return true;
    }
    return false;
  };

  const toggleShowResult = () => {
    if (errorChecker()) {
      return;
    }
    setErrorType({
      name: '',
      shift: '',
      total_guest: '',
    });

    setShowResult((prev) => !prev);
  };

  const onSubmitForm = async () => {
    const { name, address, email, phone_number, total_guest, shift, desc } = formFields;

    if (errorChecker()) return;

    setIsLoading(true);
    await onAddNewGuest({
      name: name,
      desc: desc,
      address: address,
      countryCode: selectedCountryCode,
      phoneNumber: phone_number,
      email: email,
      guest_quota: total_guest,
      guest_confirm: '0',
      shift: shift,
      status: '',
      system_info: '',
      onSuccess: (guestCode) => {
        setIsLoading(false);
        setGuestCode(guestCode);
        toast({
          title: 'Success',
          description: `Berhasil menambahkan ${formFields.name} pada list tamu`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        setErrorType({
          name: '',
          shift: '',
          total_guest: '',
        });
        setIsFormDisabled(true);
        setShowResult(true);
      },
      onFailed: () => {
        setIsLoading(false);
        toast({
          title: 'Oops!',
          description:
            'Ada kesalahan ketika menambahkan list tamu. Silahkan coba beberapa saat lagi.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  const handleCreateNewLink = () => {
    onResetForm();
    setShowResult(false);
    setBroadcastId('');
    setGuestCode('');
    setIsFormDisabled(false);
  };

  const handleChangeBroadcastType = (e) => {
    setBroadcastId(e.target.value);
  };

  const handleUpdateSentStatus = () => {
    // we don't update rsvp if user chose general invitation
    if (isGeneralInvitation) return;

    onUpdateRsvp({ guestCode, updateStatus: RSVP_STATUS.sent });
  };

  const handleCopyLink = async (customText) => {
    const result = await copyTextToClipboard(customText);

    if (result) {
      handleUpdateSentStatus();
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handleCopyText = async (customText) => {
    const result = await copyTextToClipboard(customText);

    if (broadcastId == '') {
      handlShowErrorToaster('Anda belum memilih kalimat pengantar!');
      return;
    }

    if (result) {
      handleUpdateSentStatus();
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handlShowErrorToaster = (text) => {
    toast({
      isClosable: true,
      duration: null,
      title: 'Oops!',
      status: 'error',
      description: text,
    });
  };

  const handleSentToWhatsApp = () => {
    if (broadcastId == '') {
      handlShowErrorToaster('Anda belum memilih kalimat pengantar!');
      return;
    }

    if (!formFields.phone_number || formFields.phone_number === '-') {
      handlShowErrorToaster(
        'Untuk menggunakan fitur kirim via WhatsApp, Anda perlu memasukan nomor handphone tamu yang sudah teregistrasi di WhatsApp.',
      );
      return;
    }

    if (!formFields.name) {
      handlShowErrorToaster('Nama tamu belum dimasukan!');
      return;
    }

    handleUpdateSentStatus();

    window.open(
      `https://wa.me/${selectedCountryCode}${formFields.phone_number}?text=${encodeURIComponent(
        finalTextBroadcast,
      )}`,
      '_blank',
    );
  };

  const handleGeneralInvitation = (e) => {
    const isGeneral = e.target.value === 'true';
    setIsGeneralInvitation(isGeneral);
    !isGeneral && setShowResult(false);
  };

  return (
    <Box color="black" fontFamily="Newsreader" paddingTop="24px" marginBottom="30px">
      <Box maxWidth="500px">
        <Text marginTop="16px" color="#1A202C" textTransform="uppercase">
          The Wedding of
        </Text>
        <Text fontFamily="Newsreader" color="#1A202C" fontSize="3xl">
          {THE_BRIDE}
        </Text>
        {/* Section for Name value */}
        <FormControl isInvalid={errorType.name}>
          <FormLabel {...FORM_LABEL_OPTIONS}>Nama Tamu:</FormLabel>
          <Input
            {...INPUT_PROPS}
            placeholder="Nama tamu.."
            type="text"
            value={formFields.name}
            onChange={createChangeHandler('name')}
            disabled={isFormDisabled}
          />
          <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
        </FormControl>
        {isInvitation && !ENABLE_QR_INVITATION && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Jenis Undangan:</FormLabel>
            <Button
              onClick={() => setModalState('jenisundangan')}
              {...DEFAULT_BUTTON_PROPS}
              fontWeight="normal"
              bgColor="#F0EDE6"
            >
              <Text
                marginBottom="8px"
                marginLeft="-16px"
                fontSize="14px"
                marginTop="-24px"
                fontWeight="light"
                fontStyle="italic"
                fontFamily="Newsreader"
              >
                <Icon as={QuestionOutlineIcon} />
                Apa itu Jenis Undangan?
              </Text>
            </Button>
            <Select
              marginTop="-16px"
              {...SELECT_PROPS}
              value={isGeneralInvitation}
              onChange={handleGeneralInvitation}
            >
              <option value={true}>Undangan Group</option>
              <option value={false}>Undangan Personal</option>
            </Select>
          </Box>
        )}
        {/* Section for Email value */}
        {false && (
          <FormControl>
            <FormLabel {...FORM_LABEL_OPTIONS}>Email Tamu:</FormLabel>
            <Input
              {...INPUT_PROPS}
              placeholder="Email tamu.."
              type="email"
              value={formFields.email}
              onChange={createChangeHandler('email')}
              disabled={isFormDisabled}
            />
          </FormControl>
        )}
        {/* Section for Address value */}
        {false && (
          <FormControl>
            <FormLabel {...FORM_LABEL_OPTIONS}>Address Tamu:</FormLabel>
            <Input
              {...INPUT_PROPS}
              placeholder="Address..."
              type="text"
              value={formFields.address}
              onChange={createChangeHandler('address')}
              disabled={isFormDisabled}
            />
          </FormControl>
        )}
        {/* Section for Desc value */}
        {false && (
          <FormControl>
            <FormLabel {...FORM_LABEL_OPTIONS}>Deskripsi Tamu:</FormLabel>
            <Input
              {...INPUT_PROPS}
              placeholder="Nama tamu.."
              type="text"
              value={formFields.desc}
              onChange={createChangeHandler('desc')}
              disabled={isFormDisabled}
            />
          </FormControl>
        )}
        {/* Section for Phone number */}
        {!isGeneralInvitation && (
          <FormControl>
            <FormLabel {...FORM_LABEL_OPTIONS}>Nomor HP (Whatsapp):</FormLabel>
            <InputGroup size="sm">
              <Menu>
                <MenuButton
                  {...PHONE_SELECT_PROPS}
                  as={Button}
                  rightIcon={<Icon as={BiChevronDown} />}
                  disabled={isFormDisabled || isGeneralInvitation}
                >
                  {`+${selectedCountryCode}`}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="scroll">
                  {COUNTRY_CODES.map((country) => (
                    <MenuItem
                      key={country.dial_code}
                      onClick={() => handleCountryCodeChange(country.dial_code)}
                    >
                      {`${country.name}: +${country.dial_code}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Input
                {...PHONE_INPUT_PROPS}
                placeholder="..."
                type="number"
                value={formFields.phone_number}
                onChange={createChangeHandler('phone_number')}
                disabled={isGeneralInvitation || isFormDisabled}
              />
            </InputGroup>
            <Text color="red" fontSize="xs" marginTop="8px" fontStyle="italic">
              *) Jika tidak diisi maka tombol kirim via WA akan non-aktif
            </Text>
          </FormControl>
        )}

        {/* Section for Shift - If enable shift mode and is invitation */}
        {ENABLE_SHIFT_TIME_RECEPTION && isInvitation && (
          <FormControl isInvalid={errorType.shift}>
            <FormLabel {...FORM_LABEL_OPTIONS}>Sesi Undangan:</FormLabel>
            <Select
              {...SELECT_PROPS}
              value={formFields.shift}
              onChange={createChangeHandler('shift')}
              disabled={isFormDisabled}
            >
              <option value="" style={{ color: 'black' }}>
                --- Pilih Sesi ---
              </option>
              {SHIFT_LIST.map((item) => {
                return (
                  <option key={item.value} value={item.value} style={{ color: 'black' }}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage marginTop="4px">{errorType.shift}</FormErrorMessage>
          </FormControl>
        )}
        {/* Section for Partner - If any different partner option in RSVP */}
        {isInvitation && ENABLE_GUEST_PERSONALIZATION && (
          <FormControl isInvalid={errorType.total_guest}>
            <FormLabel {...FORM_LABEL_OPTIONS}>Jumlah Tamu:</FormLabel>
            <Select
              {...SELECT_PROPS}
              value={formFields.total_guest}
              onChange={createChangeHandler('total_guest')}
              disabled={isFormDisabled}
            >
              <option style={{ color: 'black' }} value="">
                --- Pilih Jumlah Tamu ---
              </option>
              {PARTNER_LIST.map((item) => {
                return (
                  <option style={{ color: 'black' }} key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage marginTop="4px">{errorType.total_guest}</FormErrorMessage>
          </FormControl>
        )}
        {ENABLE_MULTI_LANGUAGE && (
          <Box>
            <FormLabel {...FORM_LABEL_OPTIONS}>Bahasa</FormLabel>
            <Select {...SELECT_PROPS} value={lang} onChange={(e) => setLang(e.target.value)}>
              <option style={{ color: 'black' }} value={`&lang=id`}>
                Indonesia
              </option>
              <option style={{ color: 'black' }} value={`&lang=en`}>
                English
              </option>
            </Select>
          </Box>
        )}
        {/* Tipe text broadcast */}
        <Box>
          <FormLabel {...FORM_LABEL_OPTIONS}>Teks Kalimat Pengantar:</FormLabel>
          <Button
            onClick={() => setModalState('kalimatpengantar')}
            {...DEFAULT_BUTTON_PROPS}
            fontWeight="normal"
            bgColor="#F0EDE6"
          >
            <Text
              marginBottom="8px"
              marginLeft="-16px"
              fontSize="14px"
              marginTop="-24px"
              fontWeight="light"
              fontStyle="italic"
              fontFamily="Newsreader"
            >
              <Icon as={QuestionOutlineIcon} /> Apa itu Kalimat Pengantar?
            </Text>
          </Button>
          <Select
            {...SELECT_PROPS}
            marginTop="-16px"
            value={broadcastId}
            onChange={handleChangeBroadcastType}
          >
            <option value="" style={{ color: 'black' }}>
              --- Pilih Teks Kalimat Pengantar ---
            </option>
            {broadcastOptions.map((item) => {
              return (
                <option key={item.value} value={item.value} style={{ color: 'black' }}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </Box>

        {/* Box Section */}
        <Flex justifyItems="end">
          <Spacer />
          <Button
            isLoading={isLoading}
            bgColor="#E6DBD9"
            fontWeight="bold"
            marginTop="24px"
            color="#1A202C"
            size="sm"
            border="1px"
            borderColor="black"
            onClick={
              isInvitation && isFormDisabled
                ? handleCreateNewLink
                : isInvitation && !isGeneralInvitation
                ? onSubmitForm
                : toggleShowResult
            }
            fontFamily="Newsreader"
          >
            {isInvitation && isFormDisabled
              ? 'Buat Link Invitation Baru'
              : isInvitation
              ? 'Buat Link Invitation'
              : 'Buat Link Announcement'}
          </Button>
        </Flex>
      </Box>

      {/* Show content share */}
      {showResult && (
        <Box>
          <Box
            fontSize="sm"
            bgColor="#E6DBD9"
            padding="16px 24px"
            margin="24px 0"
            borderRadius="20px"
            overflowY="auto"
          >
            {finalTextBroadcast ? (
              <pre>{finalTextBroadcast}</pre>
            ) : (
              <Text fontStyle="italic">{finalUrlLink}</Text>
            )}
          </Box>
          {/* Link Action */}
          <Text
            fontSize="xl"
            color="#1A202C"
            fontFamily="Newsreader"
            fontWeight="semibold"
            letterSpacing="1px"
            margin="12px 0"
          >
            Tahap Selanjutnya:{' '}
          </Text>
          <Box fontSize="sm">
            <Center>
              <Text margin="8px 0" color="#1A202C">
                Coba Dulu Linknya Sebelum Dikirim
              </Text>
            </Center>
            <Center>
              <Link href={finalUrlLink} target="_blank">
                <Button {...BUTTON_PROPS_OPEN}>Uji Coba Link</Button>
              </Link>
            </Center>
            <Center>
              <Text marginTop="16px" color="#1A202C">
                Pilihan 1:
              </Text>
            </Center>
            <Center>
              <Text margin="0px 0 8px 0" color="#1A202C">
                Salin Link Tanpa Teks Pengantar
              </Text>
            </Center>
            <Center>
              <Button {...BUTTON_PROPS_OPEN} onClick={() => handleCopyLink(finalUrlLink)}>
                <RiFileCopy2Line style={{ marginRight: '4px' }} />
                <Text cursor="pointer">Copy Link</Text>
              </Button>
            </Center>
            <Center>
              <Text margin="16px 0 0px 0" color="#1A202C">
                Pilihan 2:
              </Text>
            </Center>
            <Center>
              <Text margin="0px 0 8px 0" color="#1A202C">
                Salin Link + Teks Pengantar
              </Text>
            </Center>
            <Center>
              <Button {...BUTTON_PROPS_OPEN} onClick={() => handleCopyText(finalTextBroadcast)}>
                <RiFileCopy2Line style={{ marginRight: '4px' }} />
                <Text cursor="pointer">Copy Link + Teks Pengantar</Text>
              </Button>
            </Center>
            {!isGeneralInvitation && (
              <>
                <Center>
                  <Text margin="16px 0 0px 0" color="#1A202C">
                    Pilihan 3:
                  </Text>
                </Center>
                <Center>
                  <Text margin="0px 0 8px 0" color="#1A202C">
                    Kirim Link + Teks Langsung ke WA
                  </Text>
                </Center>
                {!isGeneralInvitation && (
                  <Center>
                    <Button {...BUTTON_PROPS_OPEN} onClick={handleSentToWhatsApp}>
                      <RiWhatsappLine style={{ marginRight: '4px' }} />
                      <Text cursor="pointer">Kirim Link ke WhatsApp</Text>
                    </Button>
                  </Center>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
      <ModalBroadcast visible={modalState === 'kalimatpengantar'} onClose={onClose} />
      <ModalJenisUndangan visible={modalState === 'jenisundangan'} onClose={onClose} />
    </Box>
  );
}

CustomScreen.propTypes = {
  type: oneOf(['invitation', 'announcement']),
};

export default CustomScreen;
