import {
  GIRL_NAME,
  GIRL_FATHER_NAME,
  GIRL_MOTHER_NAME,
  GIRL_NAME_SHORT,
  // boy section
  BOY_FATHER_NAME,
  BOY_NAME,
  BOY_MOTHER_NAME,
  BOY_NAME_SHORT,
  // another
  BROADCAST_WEDDING_DAY,
  BROADCAST_WEDDING_LOCATION,
  IS_BOY_FIRST,
} from '@/constants';
import { customText } from './customText';
import { InvertText } from './InvertText';
import { MESSAGE } from './templateText';

const broadcastText = IS_BOY_FIRST ? MESSAGE : InvertText;

const FINAL_BROADCAST = {
  ...broadcastText,
  ...customText,
};

function getBroadcastText({ link, guestName, id, guestConfirm, quota }) {
  const lang = id.includes('EN_') ? 'en' : 'id';

  const result = FINAL_BROADCAST[id]
    .replace('{{GUEST_NAME}}', guestName)
    .replace('{{LINK}}', link)
    .replace('{{BOY_NAME}}', BOY_NAME)
    .replace('{{GIRL_NAME}}', GIRL_NAME)
    .replace('{{GUEST_CONFIRM}}', guestConfirm)
    .replace('{{GUEST_QUOTA}}', quota)
    .replace('{{GIRL_FATHER_NAME}}', GIRL_FATHER_NAME)
    .replace('{{GIRL_MOTHER_NAME}}', GIRL_MOTHER_NAME)
    .replace('{{BOY_FATHER_NAME}}', BOY_FATHER_NAME)
    .replace('{{BOY_MOTHER_NAME}}', BOY_MOTHER_NAME)
    .replace('{{GIRL_NAME_SHORT}}', GIRL_NAME_SHORT)
    .replace('{{BOY_NAME_SHORT}}', BOY_NAME_SHORT)
    .replace('{{BROADCAST_WEDDING_LOCATION}}', BROADCAST_WEDDING_LOCATION)
    .replace('{{BROADCAST_WEDDING_DAY}}', BROADCAST_WEDDING_DAY[lang]);

  return result;
}

export default getBroadcastText;
